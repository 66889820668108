export default {
  fonts: {
    family: {},
    size: {},
  },
  colors: {},
  flexboxgrid: {
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    container: {
      sm: 38, // rem
      md: 58, // rem
      lg: 72, // rem
    },
    breakpoints: {
      xs: 576, // px
      sm: 576, // px
      md: 768, // px
      lg: 992, // px
      xl: 1200, // px
      xxl: 1400, // px
      xxxl: 1600, // px
    },
  },
  spacing: {
    xs: '5px',
    sm: '10px',
    md: '15px',
  },
};
