import styled from 'styled-components';
import {
  Button,
  Card as RTCard,
  Input as ReactstrapInput,
  InputGroup as ReactstrapInputGroup,
  InputGroupText as ReactstrapInputGroupText,
  FormFeedback as ReactstrapFormFeedback,
  CardHeader as RTCardHeader,
  CardFooter as RTCardFooter,
  CardBody as RTCardBody,
  Col,
} from 'reactstrap';

import Media from 'src/utils/media';

export const Title = styled.h1`
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;

  ${Media.xs`
    font-size: 1.8rem;
  `}
`;

export const Subtitle = styled.h4`
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: 10px 0 40px 0;

  ${Media.xs`
    font-size: 1.5rem;
  `}
`;

export const ArticleTitle = styled.h1`
  font-size: 48px;
  font-weight: bold;
  margin: 40px 0;
  text-indent: 40px;

  ${Media.xs`
    font-size: 1.8rem;
  `}
`;

export const ArticleSubtitle = styled.h4`
  margin: 40px 0;
  font-size: 28px;
  text-indent: 40px;
`;

export const ArticleSmallSubtitle = styled.h5`
  margin: 30px 0;
  font-size: 22px;
  text-indent: 40px;
`;

export const ArticleParagraph = styled.p`
  font-weight: 300;
  font-size: 16px;
  text-indent: 40px;
  text-align: left;
  margin: 10px 0;
  display: block;
`;

export const ArticleImage = styled.img`
  width: 100%;
  max-width: 960px;
  height: auto;
  display: block;
  margin: 20px auto;
`;

export const ArticleCtaButton = styled.a`
  display: block;
  padding: 18px;
  width: 360px;
  margin: 40px auto;
  text-align: center;
  background: linear-gradient(64.25deg, #5db8fb 15.64%, #35ea94 94.68%);
  border-radius: 4px;
  color: #16171b;
  font-size: 24px;
  font-weight: 400;
  border: none;
  cursor: pointer;

  &:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
    color: #16171b;
  }

  ${Media.xs`
    width: 100%;
  `}
`;

export const Icon = styled.span`
  color: ${(props) => (props.success ? '#0bb06b' : '#ef68a3')};
  font-size: 72px;
  display: block;
  text-align: center;
  margin: 20px;
`;

export const EmojiIcon = styled.b`
  margin-right: 10px;

  ${Media.md`
    margin-right: 5px
  `}
`;

export const Paragraph = styled.p`
  text-align: left;
  text-indent: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #16171b;
`;

export const Input = styled(ReactstrapInput)`
  height: 72px !important;
  font-size: 20px;
  font-weight: 300;
  color: #16171b;
  border: none;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  &:focus {
    border: 1px solid #35ea94;
    box-shadow: 0 0 3px #35ea94;
    -moz-box-shadow: 0 0 3px #35ea94;
    -webkit-box-shadow: 0 0 3px #35ea94;
  }
`;

export const InputGroupText = styled(ReactstrapInputGroupText)`
  height: 72px !important;
  width: 48px;
  font-size: 20px;
  font-weight: 300;
  color: #4a5969;
  border: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  > span {
    width: 100%;
    text-align: center;
  }
`;

export const InputGroup = styled(ReactstrapInputGroup)`
  margin: 20px 0 20px 0;
`;

export const FormFeedback = styled(ReactstrapFormFeedback)`
  font-size: 24px;
  font-weight: 300;
  margin: 16px 0 12px 0;
  color: #ffffff;
`;

export const PrimaryButton = styled(Button)`
  background-color: #ef68a3;
  border: none;
  height: 72px !important;
  font-size: 24px;

  &:hover {
    background: linear-gradient(30deg, #5db8fb, #ef68a3, #35ea94);
  }

  &:focus {
    background-color: #ef68a3;
    box-shadow: none;
  }

  &.full-width {
    width: 100%;
  }
`;

export const SecondaryButton = styled(Button)`
  background: none;
  height: 48px !important;
  font-size: 20px;
  font-weight: 300;
  color: #ef68a3;
  border: 1px solid #ef68a3;

  &:hover {
    background: #ef68a3;
    border: 1px solid #ef68a3;
  }

  &:focus {
    background: none;
    box-shadow: none;
  }

  &.full-width {
    width: 100%;
  }
`;

export const ButtonMenu = styled.span`
  display: block;
  text-align: center;
  padding: 12px;
  vertical-align: middle;
  font-size: 24px;

  &::before {
    display: block;
  }
`;

export const Card = styled(RTCard)`
  background: #27293d;
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
  padding: 4px 12px;
`;

export const CardHeader = styled(RTCardHeader)`
  padding: 16px 15px 0;
  border: 0;
  color: rgba(255, 255, 255, 0.8);
  background: none !important;

  > h4 {
    color: #ffffff;
    font-weight: 100;
    margin-bottom: 0.75rem;
    text-align: right;
  }
`;

export const CardTitle = styled.h4`
  color: #ffffff;
  font-weight: 100;
  margin-bottom: 0.75rem;
  font-size: 48px;
`;

export const CardSubtitle = styled.p`
  margin-bottom: 0;
  color: #9a9a9a;
  font-size: 18px;
  font-weight: 100;
`;

export const CardCounter = styled(CardTitle)`
  text-align: right;
`;

export const CardCounterTitle = styled(CardSubtitle)`
  text-align: right;
  font-size: 14px;
`;

export const CardIcon = styled.i`
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(${(props) => (props.boxShadowRGBA || [53, 234, 148, 0.7]).join(', ')});
  background: linear-gradient(
    64.25deg,
    ${(props) => props.colorFrom || '#5db8fb'} 15.64%,
    ${(props) => props.colorTo || '#35ea94'} 94.68%
  );
  color: #ffffff;
  font-size: 32px;
  text-align: center;
  padding-top: 17px;
  width: 68px;
  height: 68px;
`;

export const CardBody = styled(RTCardBody)`
  padding: 16px 15px 0;
  border: 0;
  color: rgba(255, 255, 255, 0.8);
  background: none !important;
  margin: 0 0 15px;
`;

export const CardFooter = styled(RTCardFooter)`
  border-color: #2b3553;
  background: none !important;

  i {
    color: #9a9a9a;
    font-size: 14px;
    margin-right: 10px;
  }

  span {
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 100;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  font-weight: 100;
  margin-bottom: 20px;
`;

export const PackageCard = styled(Col)`
  max-width: 380px !important;
`;
export const DiscountLabel = styled.span`
  display: block;
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 300;
`;

export const PackageItem = styled.div`
  min-height: 480px;
  background: #27293b;
`;

export const PackageHeader = styled.div`
  background: linear-gradient(30deg, #745cd4, 20%, #f51ffd);
  padding: 12px 0;
  position: relative;

  > h5 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: auto;
  }
`;

export const PackageBody = styled.div``;

export const ValueLabel = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
`;

export const CommentLabel = styled.span`
  display: block;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
  color: #e5e5e5;
`;

export const Features = styled.div``;

export const CountdownContainer = styled(Col)`
  position: relative;
  border-radius: 4px;
  background: linear-gradient(30deg, #5db8fb, #35ea94);
  margin-bottom: 20px;

  label {
    font-size: 24px;
    font-weight: 300;
  }
`;

export const Feature = styled.p`
  font-size: 11px;
  color: #e5e5e5;
  font-weight: 400;
  margin: 16px 0;

  > b {
    font-size: 11px;
    font-weight: 700;
    color: #ffffff;
  }

  > i {
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    padding-top: 7px;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    background: linear-gradient(64.25deg, #444658, 15.64%, #75769c 94.68%);
  }

  span {
    float: right;
    width: 80%;
    padding-top: 5px;
  }
`;
