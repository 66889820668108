import React from 'react';
import { Footer, Header } from './components';

import styles from '../../assets/css/SiteLayout.module.css';
import { Container } from 'reactstrap';

const EmptyPageLayout = ({ children, className }) => {
  return (
    <Container fluid={true} className={className + ' px-0'}>
      <Container className={styles.default}>
        <Header />
      </Container>

      {children}

      <Container className={styles.default}>
        <Footer />
      </Container>
    </Container>
  );
};

export const getLayout = (page, className) => <EmptyPageLayout className={className}>{page}</EmptyPageLayout>;

export default EmptyPageLayout;
