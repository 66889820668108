import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Button, Nav, NavLink } from 'reactstrap';
import { ButtonMenu } from 'src/components/common';
import {
  HOME,
  PRIVACY_POLICY,
  REFUND_POLICY,
  EULA,
  SIGN_IN,
  PANEL_DASHBOARD,
  BLOG_HOW_TO_IMPROVE_SEO_RANK,
  BLOG,
} from 'src/const/routes';

import styles from 'src/assets/css/SiteLayout.module.css';
import { useSelector } from 'react-redux';
export const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <div>
      <Nav
        style={{ width: isOpen ? '100%' : '0%' }}
        className="navigation d-lg-none row px-0 mx-0 justify-content-center align-content-center"
      >
        <ul
          style={{ left: isOpen ? '0px' : '-100vw' }}
          className="menu justify-content-center align-content-center px-0"
        >
          <div className="row py-2 px-sm-2 px-md-3 mx-1">
            <ButtonMenu id="button-menu-close" onClick={toggleMenu} className="col-2 d-lg-none fa fa-window-close" />
          </div>

          <div className="row justify-content-center align-content-center">
            <Link href={HOME} passHref={true}>
              <a className="logo px-0 col-8">
                <img src="/img/logo.svg" alt="trafficbooster.pro - Traffic for your website" />
              </a>
            </Link>

            <li className="col-8 px-0 nav-btn">
              <Link href={HOME + '#how-it-works'} passHref={true}>
                <a onClick={toggleMenu}>{t('layouts.SiteLayout.components.index.howItWorks')}</a>
              </Link>
            </li>
            <li className="col-8 px-0 nav-btn">
              <Link href={HOME + '#features'} passHref={true}>
                <a onClick={toggleMenu}>{t('layouts.SiteLayout.components.index.features')}</a>
              </Link>
            </li>
            <li className="col-8 px-0 nav-btn">
              <Link href={HOME + '#faq'} passHref={true}>
                <a onClick={toggleMenu}>{t('layouts.SiteLayout.components.index.faq')}</a>
              </Link>
            </li>
            <li className="col-8 px-0 nav-btn">
              <Link href={HOME + '#footer'} passHref={true}>
                <a onClick={toggleMenu}>{t('layouts.SiteLayout.components.index.contacts')}</a>
              </Link>
            </li>
          </div>

          <div className="row mt-5 pt-5 nav-bottom justify-content-center">
            <div className="col-lg-5">
              <span className={styles.address}>
                {t('layouts.SiteLayout.components.index.trafficBooster')}
                <br />
                {t('layouts.SiteLayout.components.index.adress')}
                <br />
                {t('layouts.SiteLayout.components.index.state')}
                <br />
                {t('layouts.SiteLayout.components.index.adPride')}
                <br />
                <br />
                {t('layouts.SiteLayout.components.index.mail')}
              </span>
            </div>

            <small className={styles.copyright + ' col-12 mt-5'}>
              {t('layouts.SiteLayout.components.index.adPrideLLC')}
            </small>
          </div>
        </ul>
      </Nav>
      <header
        className={
          styles.mainHeader + ' row justify-content-lg-center align-content-center justify-content-between p-2'
        }
      >
        <ButtonMenu id="button-menu" onClick={toggleMenu} className={styles.btnMenu + ' col-2 d-lg-none fa fa-bars'} />

        <div className="col-lg-2 align-self-center d-lg-block d-none position-relative">
          <Link href={HOME} passHref={true} className={styles.logo}>
            <a>
              <img src="/img/logo.svg" alt="trafficbooster.pro - Traffic for your website" />
            </a>
          </Link>
        </div>
        <div className="col-lg-6 align-self-center d-lg-block d-none">
          <Nav className="justify-content-center py-1">
            <NavLink className={styles.navLink} href={HOME + '#how-it-works'}>
              {t('layouts.SiteLayout.components.index.howItWorks')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#features'}>
              {t('layouts.SiteLayout.components.index.features')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#pricing'}>
              {t('layouts.SiteLayout.components.index.pricing')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#faq'}>
              {t('layouts.SiteLayout.components.index.faq')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#footer'}>
              {t('layouts.SiteLayout.components.index.contacts')}
            </NavLink>
          </Nav>
        </div>
        <div className="col-lg-2 col-6 py-2">
          <div className={styles.btnWrapper}>
            <Link href={isLoggedIn ? PANEL_DASHBOARD : SIGN_IN}>
              <Button type="button" className={styles.signUp + ' btn btn-secondary'}>
                {t(`layouts.SiteLayout.components.index.${isLoggedIn ? 'panel' : 'signIn'}`).toUpperCase()}
              </Button>
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="row justify-content-center align-content-center p-lg-5 p-4">
      <div className="col-lg-5">
        <span className={styles.address}>
          {t('layouts.SiteLayout.components.index.trafficBooster')}
          <br />
          {t('layouts.SiteLayout.components.index.adress')} <br />
          {t('layouts.SiteLayout.components.index.state')}
          <br />
          {t('layouts.SiteLayout.components.index.adPride')} <br />
          {t('layouts.SiteLayout.components.index.email')}:{' '}
          <a className={styles.mailLink} href="mailto:support@trafficbooster.pro">
            {t('layouts.SiteLayout.components.index.mail')}
          </a>
        </span>
      </div>

      <div className="col-lg-5">
        <div className="row justify-content-end">
          <Nav className="flex-column col-lg-4 mt-lg-0 mt-4">
            <NavLink className={styles.navLink} href={HOME + '#how-it-works'}>
              {t('layouts.SiteLayout.components.index.howItWorks')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#features'}>
              {t('layouts.SiteLayout.components.index.features')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#pricing'}>
              {t('layouts.SiteLayout.components.index.pricing')}
            </NavLink>
            <NavLink className={styles.navLink} href={BLOG}>
              {t('layouts.SiteLayout.components.index.blog')}
            </NavLink>
            <NavLink className={styles.navLink} href={BLOG_HOW_TO_IMPROVE_SEO_RANK}>
              {t('layouts.SiteLayout.components.index.howToImproveSeoRank')}
            </NavLink>
          </Nav>
          <Nav className="flex-column col-lg-4">
            <NavLink className={styles.navLink} href={HOME + '#faq'}>
              {t('layouts.SiteLayout.components.index.faq')}
            </NavLink>
            <NavLink className={styles.navLink} href={HOME + '#footer'}>
              {t('layouts.SiteLayout.components.index.contacts')}
            </NavLink>
          </Nav>
          <Nav className="nav flex-column col-lg-4">
            <Link href={PRIVACY_POLICY} passHref={true}>
              <NavLink className={styles.navLink}>{t('layouts.SiteLayout.components.index.privacyPolicy')}</NavLink>
            </Link>
            <Link href={EULA} passHref={true}>
              <NavLink className={styles.navLink}>{t('layouts.SiteLayout.components.index.eula')}</NavLink>
            </Link>
            <Link className={styles.navLink} href={REFUND_POLICY} passHref={true}>
              <NavLink className={styles.navLink}>{t('layouts.SiteLayout.components.index.refundPolicy')}</NavLink>
            </Link>
          </Nav>
        </div>
      </div>

      <small className={styles.copyright + ' col-12 mt-5'}>{t('layouts.SiteLayout.components.index.adPrideLLC')}</small>
    </footer>
  );
};
